<template>
    <a-modal
        :visible="visible"
        :title="`${!form.id ? 'Tambah' : 'Perbarui'} Tier Rule`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- tier -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Pilih Tier"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="tier_id"
                        has-feedback>
                        <FilterTierAppreciation
                            v-model:value="form.tier_id"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Level Tier"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="tier_level"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.tier_level"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- transaksi -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Min Vol Transaksi"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="min_count"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.min_count"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Maks Vol Transaksi"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="max_count"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.max_count"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- qty -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Min QTY Transaksi"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="min_volume"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.min_volume"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Maks QTY Transaksi"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="max_volume"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.max_volume"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- price -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Min Total Price"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="min_amount"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.min_amount"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Maks Total Transaksi"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="max_amount"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.max_amount"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- poin -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Min Poin"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="min_point"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.min_point"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Maks Poin"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="max_point"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.max_point"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih benefit -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Benefit"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="benefit"
                        has-feedback>
                        <FilterTierBenefit
                            mode="multiple"
                            v-model:value="form.benefit"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow class="form-row" type="flex" justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.error">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.error"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.error = null" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import FilterTierAppreciation from '@/components/filter/FilterTierAppreciation'
import FilterTierBenefit from '@/components/filter/FilterTierBenefit'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'

export default defineComponent({
    components: {
        FilterTierAppreciation,
        FilterTierBenefit,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
        rule: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            rule: '',
            vendor_type: [],
            benefit: [],
            tier_id: null,
            tier_level: null,
            min_count: null,
            max_count: null,
            min_volume: null,
            max_volume: null,
            min_amount: null,
            max_amount: null,
            min_point: null,
            max_point: null,
        }))

        const formRef = ref();

        const state = reactive({
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                tier_id: [
                    {
                        required: true,
                        message: "Tier tidak boleh kosong!",
                    },
                ],
                tier_level: [
                    {
                        required: true,
                        message: "Level tier tidak boleh kosong!",
                    },
                ],
                min_count: [
                    {
                        required: true,
                        message: "Minimal transaksi tidak boleh kosong!",
                    },
                ],
                max_count: [
                    {
                        required: true,
                        message: "Maksimal transaksi tidak boleh kosong!",
                    },
                ],
                min_volume: [
                    {
                        required: true,
                        message: "Minimal QTY tidak boleh kosong!",
                    },
                ],
                max_volume: [
                    {
                        required: true,
                        message: "Maksimal QTY tidak boleh kosong!",
                    },
                ],
                min_amount: [
                    {
                        required: true,
                        message: "Minimal price tidak boleh kosong!",
                    },
                ],
                max_amount: [
                    {
                        required: true,
                        message: "Maksimal price tidak boleh kosong!",
                    },
                ],
                min_point: [
                    {
                        required: true,
                        message: "Minimal point tidak boleh kosong!",
                    },
                ],
                max_point: [
                    {
                        required: true,
                        message: "Maksimal point tidak boleh kosong!",
                    },
                ],
                benefit: [
                    {
                        required: true,
                        message: "Benefit tidak boleh kosong!",
                    },
                ],
            },
            error: null,
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(`/api/appreciation-rule-create/${props.rule.id}`)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(async error => {
                        state.error = null
                        if (error.response) {
                            state.error = error.response.data.error
                        }
                    })
            } else {
                form.post(`/api/appreciation-rule-update/${form.id}`)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(async error => {
                        state.error = null
                        if (error.response) {
                            state.error = error.response.data.error
                        }
                    })
            }

        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()

                form.vendor_type = [props.rule.vendor_type]
            } else {
                form.rule = props.item.rule
                form.vendor_type = [props.item.vendor_type]
                form.benefit = props.item.benefit_ids
                form.tier_id = props.item.tier_id
                form.tier_level = props.item.tier_level
                form.min_count = props.item.min_count
                form.max_count = props.item.max_count
                form.min_volume = props.item.min_volume
                form.max_volume = props.item.max_volume
                form.min_amount = props.item.min_amount
                form.max_amount = props.item.max_amount
                form.min_point = props.item.min_point
                form.max_point = props.item.max_point
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
