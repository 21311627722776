<template>
    <a-modal
        :visible="visible"
        :title="`${!form.id ? 'Tambah' : 'Perbarui'} Tier Rule`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- nama rule -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Nama Rule"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="rule"
                        has-feedback>
                        <AInput
                            placeholder="nama rule"
                            v-model:value="form.rule"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih customer -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Pelanggan"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="vendor_type"
                        has-feedback>
                        <FilterTierVendorType
                            mode="multiple"
                            v-model:value="form.vendor_type"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- tier baru -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <ASpace
                        direction="vertical"
                        style="width:100%">
                        <ACard
                            v-for="(item, index) in form.tier"
                            :key="index"
                            size="small">
                            <strong class="mb-2">Tier Baru</strong>

                            <!-- tier -->
                            <ARow class="form-row">
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Pilih Tier"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'tier_id']"
                                        has-feedback>
                                        <FilterTierAppreciation
                                            v-model:value="item.tier_id"/>
                                    </AFormItem>
                                </ACol>
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Level Tier"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'tier_level']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.tier_level"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>

                            <!-- transaksi -->
                            <ARow class="form-row">
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Min Vol Transaksi"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'min_count']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.min_count"/>
                                    </AFormItem>
                                </ACol>
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Maks Vol Transaksi"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'max_count']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.max_count"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>

                            <!-- qty -->
                            <ARow class="form-row">
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Min QTY Transaksi"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'min_volume']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.min_volume"/>
                                    </AFormItem>
                                </ACol>
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Maks QTY Transaksi"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'max_volume']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.max_volume"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>

                            <!-- price -->
                            <ARow class="form-row">
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Min Total Price"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'min_amount']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.min_amount"/>
                                    </AFormItem>
                                </ACol>
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Maks Total Transaksi"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'max_amount']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.max_amount"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>

                            <!-- poin -->
                            <ARow class="form-row">
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Min Poin"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'min_point']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.min_point"/>
                                    </AFormItem>
                                </ACol>
                                <ACol :md="12" :sm="24">
                                    <AFormItem
                                        label="Maks Poin"
                                        label-align="left"
                                        :label-col="{ sm: { span: 8 } }"
                                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                                        required
                                        :name="['tier', index, 'max_point']"
                                        has-feedback>
                                        <AInput
                                            placeholder="number"
                                            type="number"
                                            :min="0"
                                            v-model:value="item.max_point"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>

                            <!-- pilih benefit -->
                            <ARow class="form-row">
                                <ACol :sm="24">
                                    <AFormItem
                                        label="Pilih Benefit"
                                        label-align="left"
                                        :label-col="{ sm: { span: 4 } }"
                                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                                        required
                                        :name="['tier', index, 'benefit']"
                                        has-feedback>
                                        <FilterTierBenefit
                                            mode="multiple"
                                            v-model:value="item.benefit"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>

                            <!-- delete tier -->
                            <ARow class="form-row" type="flex" justify="end">
                                <ACol>
                                    <AFormItem>
                                        <AButton
                                            type="danger"
                                            size="small"
                                            @click="removeRowReference(item)">
                                            <i class="fa fa-trash mr-2" /> Hapus Tier
                                        </AButton>
                                    </AFormItem>
                                </ACol>
                            </ARow>
                        </ACard>
                    </ASpace>
                </ACol>
            </ARow>
            <!-- end tier baru -->
            
            <!-- add tier -->
            <ARow class="form-row" type="flex" justify="start">
                <ACol>
                    <AFormItem>
                        <AButton
                            type="primary"
                            size="small"
                            @click="AddReference">
                            <i class="fa fa-plus mr-2" /> Tambahkan Tier
                        </AButton>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow class="form-row" type="flex" justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.error">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.error"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.error = null" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import FilterTierAppreciation from '@/components/filter/FilterTierAppreciation'
import FilterTierVendorType from '@/components/filter/FilterTierVendorType'
import FilterTierBenefit from '@/components/filter/FilterTierBenefit'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'

export default defineComponent({
    components: {
        FilterTierAppreciation,
        FilterTierVendorType,
        FilterTierBenefit,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            rule: '',
            vendor_type: [],
            tier: [],
        }))

        const formRef = ref();

        const state = reactive({
            data: [],
            reference: {
                benefit: [],
                tier_id: null,
                tier_level: null,
                min_count: null,
                max_count: null,
                min_volume: null,
                max_volume: null,
                min_amount: null,
                max_amount: null,
                min_point: null,
                max_point: null,
            },
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                rule: [
                    {
                        required: true,
                        message: "Nama rule tidak boleh kosong!",
                    },
                ],
                vendor_type: [
                    {
                        required: true,
                        message: "Pelanggan tidak boleh kosong!",
                    },
                ],
                tier: [],
            },
            rule_references: {
                tier_id: [
                    {
                        required: true,
                        message: "Tier tidak boleh kosong!",
                    },
                ],
                tier_level: [
                    {
                        required: true,
                        message: "Level tier tidak boleh kosong!",
                    },
                ],
                min_count: [
                    {
                        required: true,
                        message: "Minimal transaksi tidak boleh kosong!",
                    },
                ],
                max_count: [
                    {
                        required: true,
                        message: "Maksimal transaksi tidak boleh kosong!",
                    },
                ],
                min_volume: [
                    {
                        required: true,
                        message: "Minimal QTY tidak boleh kosong!",
                    },
                ],
                max_volume: [
                    {
                        required: true,
                        message: "Maksimal QTY tidak boleh kosong!",
                    },
                ],
                min_amount: [
                    {
                        required: true,
                        message: "Minimal price tidak boleh kosong!",
                    },
                ],
                max_amount: [
                    {
                        required: true,
                        message: "Maksimal price tidak boleh kosong!",
                    },
                ],
                min_point: [
                    {
                        required: true,
                        message: "Minimal point tidak boleh kosong!",
                    },
                ],
                max_point: [
                    {
                        required: true,
                        message: "Maksimal point tidak boleh kosong!",
                    },
                ],
                benefit: [
                    {
                        required: true,
                        message: "Benefit tidak boleh kosong!",
                    },
                ],
            },
            error: null,
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const removeRowReference = (item) => {
            const index = form.tier.indexOf(item)

            if (item !== -1) {
                form.tier.splice(index, 1)
                state.rules.tier.splice(index, 1)
            }
        }

        const AddReference = () => {
            if (form.tier.filter(v => v.tier_id === null).length > 0) {
                message.warning('Pastikan tier sebelumnya terisi!')
                return
            }
            
            form.tier.push(Object.assign({}, state.reference))
            state.rules.tier.push(Object.assign({}, state.rule_references))
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post('/api/appreciation-rule')
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(async error => {
                        state.error = null
                        if (error.response) {
                            state.error = error.response.data.error
                        }
                    })
            } else {
                form.put(`/api/appreciation-rule/${form.id}`)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(async error => {
                        state.error = null
                        if (error.response) {
                            state.error = error.response.data.error
                        }
                    })
            }

        }

        // fetch by id
        const fetchByID = (id) => {
            apiClient
                .get(`/api/appreciation-rule/${id}`)
                .then(({ data }) => {
                    data.forEach(item => {
                        form.rule = item.rule

                        form.tier.push({
                            tier_id: item.tier_id,
                            tier_level: item.tier_level,
                            min_count: item.min_count,
                            max_count: item.max_count,
                            min_volume: item.min_volume,
                            max_volume: item.max_volume,
                            min_amount: item.min_amount,
                            max_amount: item.max_amount,
                            min_point: item.min_point,
                            max_point: item.max_point,
                            benefit: item.benefit_ids,
                        })
                        
                        state.rules.tier.push(Object.assign({}, state.rule_references))
                    })

                    form.vendor_type = data.map(item => item.vendor_type)
                })
        }

        // handle func vue
        onMounted(() => {
            form.reset()
            fetchByID(props.item.id)
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            removeRowReference,
            AddReference,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
